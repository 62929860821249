exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-babies-infant-feeding-coaching-js": () => import("./../../../src/pages/babies/infant-feeding-coaching.js" /* webpackChunkName: "component---src-pages-babies-infant-feeding-coaching-js" */),
  "component---src-pages-babies-newborn-check-js": () => import("./../../../src/pages/babies/newborn-check.js" /* webpackChunkName: "component---src-pages-babies-newborn-check-js" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-avoid-the-christmas-crunch-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/avoid-the-christmas-crunch.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-avoid-the-christmas-crunch-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-5-am-club-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-5am-club.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-5-am-club-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-52-ways-to-walk-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-52-ways-to-walk.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-52-ways-to-walk-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-beating-osteoporosis-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-beating-osteoporosis.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-beating-osteoporosis-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-body-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-body.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-body-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-boundaries-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-boundaries.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-boundaries-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-breath-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-breath.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-breath-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-exercised-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-exercised.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-exercised-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-glucose-revolution-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-glucose-revolution.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-glucose-revolution-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-high-on-life-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-high-on-life.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-high-on-life-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-how-to-be-a-fit-bird-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-how-to-be-a-fit-bird.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-how-to-be-a-fit-bird-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-how-to-prevent-dementia-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-how-to-prevent-dementia.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-how-to-prevent-dementia-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-managing-your-migraine-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-managing-your-migraine.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-managing-your-migraine-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-perimenopause-menopause-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-perimenopause-menopause.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-perimenopause-menopause-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-preparing-for-the-perimenopause-and-menopause-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-preparing-for-the-perimenopause-and-menopause.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-preparing-for-the-perimenopause-and-menopause-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-the-immune-mind-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-the-immune-mind.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-the-immune-mind-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-the-science-of-living-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-the-science-of-living.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-the-science-of-living-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-ultra-processed-people-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-ultra-processed-people.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-ultra-processed-people-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-why-we-sleep-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-why-we-sleep.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-why-we-sleep-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-wintering-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/book-review-wintering.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-book-review-wintering-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-boots-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/boots.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-boots-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-coping-with-lower-back-pain-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/coping-with-lower-back-pain.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-coping-with-lower-back-pain-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-discussing-osteopathy-and-the-older-patient-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/discussing-osteopathy-and-the-older-patient.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-discussing-osteopathy-and-the-older-patient-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-discussing-smart-goals-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/discussing-smart-goals.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-discussing-smart-goals-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-embracing-winter-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/embracing-winter.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-embracing-winter-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ergonomic-advice-to-keep-your-physical-health-when-at-work-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/ergonomic-advice-to-keep-your-physical-health-when-at-work.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ergonomic-advice-to-keep-your-physical-health-when-at-work-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ergonomics-for-our-children-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/ergonomics-for-our-children.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ergonomics-for-our-children-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-exercise-it-is-never-too-late-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/exercise-it-is-never-too-late.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-exercise-it-is-never-too-late-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-exercising-in-hot-weather-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/exercising-in-hot-weather.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-exercising-in-hot-weather-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-february-longer-days-sunshine-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/february-longer-days-sunshine.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-february-longer-days-sunshine-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-flipflops-are-a-flop-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/flipflops-are-a-flop.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-flipflops-are-a-flop-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-get-out-get-moving-this-spring-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/get-out-get-moving-this-spring.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-get-out-get-moving-this-spring-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-health-is-wealth-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/health-is-wealth.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-health-is-wealth-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-high-heeled-shoes-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/high-heeled-shoes.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-high-heeled-shoes-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-how-to-avoid-a-painful-christmas-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/how-to-avoid-a-painful-christmas.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-how-to-avoid-a-painful-christmas-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-how-to-winter-well-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/how-to-winter-well.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-how-to-winter-well-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ice-and-heat-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/ice-and-heat.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-ice-and-heat-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-inspiring-indoor-exercise-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/inspiring-indoor-exercise.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-inspiring-indoor-exercise-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-jaw-wars-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/jaw-wars.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-jaw-wars-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-jones-the-bones-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/jones-the-bones.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-jones-the-bones-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-just-do-one-thing-to-start-a-change-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/just-do-one-thing-to-start-a-change.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-just-do-one-thing-to-start-a-change-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-kicking-convenience-to-the-curb-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/kicking-convenience-to-the-curb.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-kicking-convenience-to-the-curb-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-knee-pain-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/knee-pain.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-knee-pain-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-looking-after-teenagers-health-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/looking-after-teenagers-health.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-looking-after-teenagers-health-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-looking-after-your-body-in-the-garden-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/looking-after-your-body-in-the-garden.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-looking-after-your-body-in-the-garden-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-march-pupdate-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/march-pupdate.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-march-pupdate-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-osteopathy-and-its-royal-connections-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/osteopathy-and-its-royal-connections.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-osteopathy-and-its-royal-connections-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-osteopathy-isnt-for-me-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/osteopathy-isnt-for-me.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-osteopathy-isnt-for-me-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-paced-responsive-bottle-feeding-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/paced-responsive-bottle-feeding.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-paced-responsive-bottle-feeding-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-pelvic-health-more-than-the-pelvic-floor-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/pelvic-health-more-than-the-pelvic-floor.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-pelvic-health-more-than-the-pelvic-floor-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-is-not-selfish-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/self-care-is-not-selfish.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-is-not-selfish-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/self-care.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-vs-self-love-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/self-care-vs-self-love.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-self-care-vs-self-love-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-staying-fit-in-colder-weather-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/staying-fit-in-colder-weather.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-staying-fit-in-colder-weather-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-stepping-into-spring-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/stepping-into-spring.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-stepping-into-spring-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-survival-guide-for-trips-and-holidays-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/survival-guide-for-trips-and-holidays.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-survival-guide-for-trips-and-holidays-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-the-importance-of-stretching-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/the-importance-of-stretching.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-the-importance-of-stretching-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-the-rubbish-theory-of-no-pain-no-gain-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/the-rubbish-theory-of-no-pain-no-gain.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-the-rubbish-theory-of-no-pain-no-gain-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-time-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/time.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-time-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-time-to-get-outside-again-and-look-after-your-body-in-the-garden-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/time-to-get-outside-again-and-look-after-your-body-in-the-garden.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-time-to-get-outside-again-and-look-after-your-body-in-the-garden-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-travel-tips-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/travel-tips.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-travel-tips-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-walking-poles-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/walking-poles.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-walking-poles-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-what-i-offer-everything-for-free-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/what-i-offer-everything-for-free.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-what-i-offer-everything-for-free-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-why-sciatica-is-not-a-diagnosis-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/why-sciatica-is-not-a-diagnosis.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-why-sciatica-is-not-a-diagnosis-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-winter-warmer-sausage-pie-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/winter-warmer-sausage-pie.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-winter-warmer-sausage-pie-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-young-and-old-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/content/articles/young-and-old.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-content-articles-young-and-old-mdx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-osteopathy-conditions-js": () => import("./../../../src/pages/osteopathy/conditions.js" /* webpackChunkName: "component---src-pages-osteopathy-conditions-js" */),
  "component---src-pages-osteopathy-cranial-osteopathy-js": () => import("./../../../src/pages/osteopathy/cranial-osteopathy.js" /* webpackChunkName: "component---src-pages-osteopathy-cranial-osteopathy-js" */),
  "component---src-pages-osteopathy-index-js": () => import("./../../../src/pages/osteopathy/index.js" /* webpackChunkName: "component---src-pages-osteopathy-index-js" */),
  "component---src-pages-osteopathy-osteopathy-and-babies-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-babies.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-babies-js" */),
  "component---src-pages-osteopathy-osteopathy-and-pregnancy-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-pregnancy.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-pregnancy-js" */),
  "component---src-pages-osteopathy-osteopathy-and-sports-injuries-js": () => import("./../../../src/pages/osteopathy/osteopathy-and-sports-injuries.js" /* webpackChunkName: "component---src-pages-osteopathy-osteopathy-and-sports-injuries-js" */),
  "component---src-pages-osteopathy-what-to-expect-js": () => import("./../../../src/pages/osteopathy/what-to-expect.js" /* webpackChunkName: "component---src-pages-osteopathy-what-to-expect-js" */),
  "component---src-pages-pilates-index-js": () => import("./../../../src/pages/pilates/index.js" /* webpackChunkName: "component---src-pages-pilates-index-js" */),
  "component---src-pages-pilates-prenatal-postnatal-pilates-js": () => import("./../../../src/pages/pilates/prenatal-postnatal-pilates.js" /* webpackChunkName: "component---src-pages-pilates-prenatal-postnatal-pilates-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-visual-language-js": () => import("./../../../src/pages/visual-language.js" /* webpackChunkName: "component---src-pages-visual-language-js" */),
  "component---src-pages-womens-health-postnatal-check-js": () => import("./../../../src/pages/womens-health/postnatal-check.js" /* webpackChunkName: "component---src-pages-womens-health-postnatal-check-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */)
}

